@import "partials/variables"

.login-page
  background $color-main
  overflow-y auto

.login-page__form-signin
  padding 0 20px

.login-page__image
  display block
  margin 15vh auto 100px auto
  width 240px
  max-width 100%
  height auto

.login-page__inputs
  max-width: 320px;
  margin: 0 auto;
  text-align center
  & input
    margin-bottom 18px
    text-align center
    border: none
    border-radius: 2px
    &:-webkit-autofill
      -webkit-box-shadow: 0 0 0px 1000px white inset;
  & input
  & button
    height: 40px
    font-size: 16px

.login-page__btn
  margin-bottom 40px

.login-page__forgot
  font-weight 400
  color #fff
  transition $transition
  opacity 0.7
  &:hover
    text-decoration none
    color #fff
    opacity 1
